const routes = [
    {
    path: "/",
    props: true,
    name: 'Pega2',
    // component: () => import("@/components/HelloWorld.vue"),
    // component: () => import("@/components/another/ApiDetails.vue"),
    component: () => import(/* webpackChunkName: "about" */ '../views/Pega2.vue')

    // component: Home
    },
    {
    path: '/pega3',
    name: 'Pega3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pega3.vue')
    },
    {
    path: '/pega4',
    name: 'Pega4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pega4.vue')
    },
]

export default routes;
