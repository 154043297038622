export default {
    async lotteryOfPuertoRico({commit, dispatch}, match) { 
            // console.log(match)
            
            localStorage.setItem('jugada', JSON.stringify(match))
            let test = localStorage.getItem('jugada')
            let date = match.fecha
            let responseDay = await fetch(`https://wordpresswebapi.azurewebsites.net/api/ElectronicDrawing/ElectronicDrawingDateSearch/${match.pegaDay}/${date}/${match.endDate}`)
            let day = await responseDay.json()
            let responseNight = await fetch(`https://wordpresswebapi.azurewebsites.net/api/ElectronicDrawing/ElectronicDrawingDateSearch/${match.pegaNight}/${date}/${match.endDate}`)
            let night = await responseNight.json()
            let payload = {'day': day, 'night': night}
            localStorage.setItem('numbers', JSON.stringify(payload))
            commit('sendData', payload)
        },
    async lotteryOfPuertoRicoNight({commit}, match) {
        let responseNight = await fetch(`https://wordpresswebapi.azurewebsites.net/api/ElectronicDrawing/ElectronicDrawingDateSearch/${match.pegaNight}/2022-01-01/${currentDate}`)
        let night = await responseNight.json()

        commit('sendDataNight', night)
    },

    getAllCombi({commit}, teste) {
    
        // console.log(teste)
        let getAllPermutations = (string) => {
            
            let results = [];
            if (string.length === 1) {
                results.push(string);
                return results;
            }
            for (let i = 0; i < string.length; i++) {
                let firstChar = string[i];
                let charsLeft = string.substring(0, i) + string.substring(i + 1);
                
                let innerPermutations = getAllPermutations(charsLeft);
                // console.log(innerPermutations)
                for (let j = 0; j < innerPermutations.length; j++) {
                    results.push(firstChar + innerPermutations[j]);
                }
            }
            results = results.filter((item,index) => results.indexOf(item) === index);
            return results;
            
        }

        commit('allCombimation', getAllPermutations(teste))
        
    },

    async getNumberFromTable({commit, dispatch}) {
        let dd = document.getElementsByClassName('wow');
            Array.from(dd).forEach(element => {
              element.addEventListener('click', async () => {
                let tableNumber = element.dataset.wow;
                dispatch('missingCombination/getTheNumbers', tableNumber, {root: true})
                commit('tableNumber', tableNumber)
              })
            })
    },

}