<template>
  <div id="nav">
    <router-link to="/">Pega 2</router-link> |
    <router-link to="/pega3">Pega 3</router-link> |
    <router-link to="/pega4">Pega 4</router-link>
  </div>
  <router-view/>
  <div class="footer-copyright text-center py-3">© 2023 Copyright:
    <a href="https://github.com/anferneeabril" target="_blank" rel="noopener noreferrer"> Anfernee Abril</a>
  </div>
</template>

<style>
body {
  background-color: #ececec;
}
#app {
  /* font-family: Courier New,Courier,Monaco,monospac; */
  font-family: Calibri, sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #9fd3c7;
}

#nav a.router-link-exact-active {
  color: #142d4c;
}

@media (max-width: 576px) {
  #nav { 
    padding-bottom: 10px;
  }
}

</style>
