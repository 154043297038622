import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/dist/sweetalert2.min.css';
// import 'sweetalert2/src/sweetalert2.scss'


export default {
    getTheNumbers({commit, dispatch}, number) {
        let getNumbersPlay = localStorage.getItem('numbers')
        let convertNumbersPlay = JSON.parse(getNumbersPlay)
        let convertNumbersPlayArray = []
        convertNumbersPlay.day.forEach(element => {
            convertNumbersPlayArray.push(element.results)
        })
        convertNumbersPlay.night.forEach(element => {
            convertNumbersPlayArray.push(element.results)
        })
        dispatch('missingCombination/getCombination', {dataNumber: number, dataArray :convertNumbersPlayArray}, {root: true})
    },
    
       /**
         * 
         * 
         * @param {array} numbers All the numbers given in the lottery (depending on which date the user chooses) 
         * @yield {array}  
         */
        getCombination({dispatch, commit}, payload){
            let filterArr = [];
            let combinationArrays = [];
            let allCombimationOutPut = [];
            let objectCombinationResult = new Object();
            /**
             * Return result all combination of a number     
             * 
             * @param {string/number} string  
             * @return {string/number} string Return all combination of a number
            */
            let getAllPermutations = (string) => {
                let results = [];
                if (string.length === 1) {
                    results.push(string);
                    return results;
                }
                for (let i = 0; i < string.length; i++) {
                    let firstChar = string[i];
                    let charsLeft = string.substring(0, i) + string.substring(i + 1);
                    
                    let innerPermutations = getAllPermutations(charsLeft);
                    for (let j = 0; j < innerPermutations.length; j++) {
                        results.push(firstChar + innerPermutations[j]);
                    }
                }
                
                results = results.filter((item,index) => results.indexOf(item) === index);
                return results;
            }
        
        payload.dataArray.forEach((numbersApi, index) => {
            let values = getAllPermutations(numbersApi)
            combinationArrays.push(values)
        })
        let indexSearch;
        for(let index = 0; combinationArrays.length > index; index++) {
            let iterateAllCombination = combinationArrays[index]
            for(let i = 0; iterateAllCombination.length > i; i++) {
                let all = iterateAllCombination[i]
                if(payload.dataNumber == all) {
                    indexSearch = index
                }
            }
        }

        if(indexSearch === undefined) {
                indexSearch = null
                this.alreadyHappened = 'Ninguno'
                this.allCombination = 'nada'
                this.missingNumbers = 'Toda la Combinación'
            }
            else {
                let testPush = []
                testPush.push(combinationArrays[indexSearch])

                let test = combinationArrays[indexSearch]
                let allCombiNumbers = combinationArrays[indexSearch]
                objectCombinationResult.allCombinationData = test
                this.allCombination = testPush

                // numbers = numeros dados en la loteria
                for(let i = payload.dataArray.length -1; i >= 0; i --) {
                    let allNumbers = payload.dataArray[i] 
                    for(let index = combinationArrays[indexSearch].length -1; index >= 0; index--) {
                        let combination = combinationArrays[indexSearch][index]
                        if(allNumbers == combination) {
                            combinationArrays[indexSearch].splice(index, 1)        
                        }
                        else {
                            filterArr.push(combinationArrays[indexSearch][index])
                        }
                    }
                }
                let combinationArrayFilter = filterArr.filter(fill => !combinationArrays.includes(fill))
                filterArr = combinationArrayFilter.filter((item,index) => filterArr.indexOf(item) === index);
                for(let index = filterArr.length -1; index >= 0; index--) {
                    // itera las 6 combinaciones. filterArr es la combinacion completa
                    let filterIterate = filterArr[index]
                    for(let i = combinationArrays[indexSearch].length; i >= 0; i--) {
                        let combinationIterate = combinationArrays[indexSearch][i]
                        if(filterIterate == combinationIterate) {
                            filterArr.splice(index, 1)
                        }
                    }
                }
                filterArr = filterArr.filter(fill => !combinationArrays.includes(fill))                            
                // console.log('Ya se dieron\n', filterArr)
                // console.log('Combinations that have not been given\n',combinationArrays[indexSearch])
                objectCombinationResult.theyAlreadyOccurred = filterArr
                objectCombinationResult.CombinationsnotBeenGiven = combinationArrays[indexSearch]
                dispatch('missingCombination/customAlertCombination', objectCombinationResult, {root: true})            
            }
        },
        
    customAlertCombination({commit, dispatch}, combinationObject) {
       if(combinationObject.CombinationsnotBeenGiven.length === 0) {
        Swal.fire({
                title: 'Combinación',
                html: `<p><b>Toda la combinación ya se dio</b></p>` + `<p><strong>${combinationObject.theyAlreadyOccurred}</strong></p>`,
                confirmButtonColor: '#385170',
                background: '#ececec'
            })           
        }
        else {
            Swal.fire({
                title: 'Combinación',
                html: `<p>Números faltantes en la combinación: <strong>${combinationObject.CombinationsnotBeenGiven}</strong> </p>` + 
                `<p>Números faltantes en la combinación: <strong>${combinationObject.theyAlreadyOccurred},</strong></p>`,
                confirmButtonColor: '#385170',
                background: '#ececec'
            })
        }
    }
}