import { createStore } from 'vuex'

import lotteryApi from './module/lotteryApi/index.js'
import missingCombination from './module/missingCombination/index.js'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    lotteryApi,
    missingCombination
  }
})
