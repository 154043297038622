import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Router from 'vue-router';                                
import plays from './plays';

const baseRoutes = []
const routes = baseRoutes.concat(plays);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'hitory',
  routes
  //[
  //   {
  //     path: '/:catchAll(.*)', 
  //     component: NotFoundComponent,
  //     name: 'NotFound'
  //   }
  // ]
})

router.beforeEach((to, from, next) => {
  console.log(`Navigation to: ${to.name}`)
  next();
});

export default router;