import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export default {
    sendData(state, payload) {
        payload.day.forEach((objDay, index) => {
            let getDatesFromDay = objDay.drawingDate
            let changeFormatDay = moment(getDatesFromDay).format("dddd, MMMM DD YYYY")
            payload.day[index].drawingDate = changeFormatDay
        })

        payload.night.forEach((objNight, index) => {
            let getDatesFromNight = objNight.drawingDate
            let changeFormatNight = moment(getDatesFromNight).format("dddd, MMMM DD YYYY")
            payload.night[index].drawingDate = changeFormatNight
        })

        state.dataMatch.day = payload.day.reverse()
        state.dataMatch.night = payload.night.reverse()
    }, 

    sendDataNight(state, payload) {
        console.log(payload)
    },

    allCombimation(state, data) {
        state.fullCombination.numbers = data
    },

    tableNumber(state, number) {
        state.numbersFromTable.numbersTable = number
    }
}