export default {
    dataMatch: {
        day: '',
        night: ''
    },

    fullCombination: {
        numbers: ''
    },

    numbersFromTable: {
        numbersTable: ''
    }

    
}